import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { map, tap } from 'rxjs';
import type { ClientSettings } from '@launch-deck/common';
import { ClientHubService } from 'src/app/services/client-hub.service';
import { AgentCodeService } from 'src/app/services/agent-code.service';

const iconAnimation = trigger('iconAnimation', [
    transition('* <=> *', [
        query(':enter',
            [style({ opacity: 0 }), stagger('50ms', animate('400ms ease-out', style({ opacity: 1 })))],
            { optional: true }
        ),
        query(':leave',
            [animate('400ms ease-out', style({ opacity: 0 }))],
            { optional: true }
        )
    ])
]);

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css'],
    animations: [iconAnimation]
})
export class ProfileComponent implements OnInit {

    showIcons: boolean = false;
    lockApp: boolean = false;
    clientSettings: ClientSettings;

    constructor(private clientHub: ClientHubService,
        private agentCodeService: AgentCodeService) { }

    ngOnInit(): void {
        this.clientHub.data.pipe(
            tap(data => {
                if (data.tiles.length === 0) {
                    this.showIcons = true;
                }
            }),
            map(data => data.clientSettings),
        ).subscribe(settings => {
            this.clientSettings = settings;
            if (settings?.backgroundImageUrl) {
                document.body.style.backgroundImage = 'url(' + settings.backgroundImageUrl + ')';
            }
        });
    }

    exitAgent(): void {
        this.agentCodeService.setAgentCode("");
        document.body.style.backgroundImage = '';
    }

    reload(): void {
        location.reload();
    }

}
