import { Component, OnInit } from '@angular/core';
import { ClientHubService } from './services/client-hub.service';
import { AgentCodeService } from './services/agent-code.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {

    connectedToAgent: boolean = false;
    selectedAgentCode: string = "";
    rememberedAgentCodes: string[];
    errorMessage: string = "";

    constructor(private agentCodeService: AgentCodeService,
        private clientHub: ClientHubService) { }

    ngOnInit(): void {
        this.initRememberedAgentCodes();

        const lastAgentCode = this.getLastAgentCode();
        if (lastAgentCode) {
            this.agentCodeService.setAgentCode(lastAgentCode);
        }
        this.agentCodeService.agentCode.subscribe(async agentCode => {

            if (!!agentCode) {
                try {
                    await this.clientHub.connect(agentCode);
                    this.connectedToAgent = true;
                    this.setLastAgentCode(agentCode);
                    this.selectedAgentCode = "";
                } catch (error) {
                    this.setLastAgentCode("");
                    this.errorMessage = "Could not connect to the Agent. Make sure the Agent is running and the Code is correct.";
                }
            } else {
                this.connectedToAgent = false;
                this.setLastAgentCode("");
            }
        });
    }

    selectAgent(agentCode: string): void {
        if (agentCode) {
            this.errorMessage = "";
            this.agentCodeService.setAgentCode(agentCode);

            if (!this.rememberedAgentCodes.includes(agentCode)) {
                this.rememberedAgentCodes.unshift(agentCode);
                this.setRememberedAgentCodes();
            }
        }
    }

    removeRememberedAgentCode(agent: string): void {
        if (this.rememberedAgentCodes.includes(agent)) {
            this.rememberedAgentCodes.splice(this.rememberedAgentCodes.indexOf(agent), 1);
            this.setRememberedAgentCodes();
        }
    }

    private initRememberedAgentCodes(): void {
        let storedValue = localStorage.getItem("rememberedAgents");
        if (storedValue) {
            this.rememberedAgentCodes = JSON.parse(storedValue);
        } else {
            this.rememberedAgentCodes = [];
        }
    }

    private setRememberedAgentCodes(): void {
        localStorage.setItem("rememberedAgents", JSON.stringify(this.rememberedAgentCodes));
    }

    private getLastAgentCode(): string | null {

        const urlSearchParams = new URLSearchParams(window.location.search);
        const urlAgent = urlSearchParams.get("agent");

        return urlAgent || localStorage.getItem("lastAgent");
    }

    private setLastAgentCode(agent: string): void {
        localStorage.setItem("lastAgent", agent);
    }

}
