<div class="tile-container root"
     #rootTiles
     (swiperight)="previousRootPage()"
     (swipeleft)="nextRootPage()">

    <div class="tiles" *ngIf="tiles" [@listAnimation]="rootChanged">
        <div *ngFor="let tile of tiles; index as i" 
             class="tile" 
             [class.selected]="tile.id === selectedTile?.id"
             [ngStyle]="getTileStyle(tile)"
             matRipple 
             (click)="selectTile(tile)">
            <mat-icon *ngIf="tile.icon">{{tile.icon}}</mat-icon>
            <span class="name">{{tile.name}}</span>
        </div>
    </div>

    <div class="previous" [class.show]="showRootPreviousPage">
        <button type="button" mat-mini-fab (click)="previousRootPage()">
            <mat-icon>chevron_left</mat-icon>
        </button>
    </div>
    <div class="next" [class.show]="showRootNextPage">
        <button type="button" mat-mini-fab (click)="nextRootPage()">
            <mat-icon>chevron_right</mat-icon>
        </button>
    </div>

</div>

<div class="tile-container children"
     [class.has-children]="hasChildren" 
     #childTiles
     (swiperight)="previousChildPage()"
     (swipeleft)="nextChildPage()">
    
    <div class="tiles" *ngIf="children" [@listAnimation]="childChanged" [ngStyle]="getChildBackgroundStyle()">
        <div *ngFor="let tile of children; index as i" 
             class="tile" 
             [ngStyle]="getTileStyle(tile)"
             matRipple 
             (click)="selectTile(tile)">
            <mat-icon *ngIf="tile.icon">{{tile.icon}}</mat-icon>
            <span class="name">{{tile.name}}</span>
        </div>
    </div>

    <div class="previous" [class.show]="showChildPreviousPage">
        <button type="button" mat-mini-fab (click)="previousChildPage()">
            <mat-icon>chevron_left</mat-icon>
        </button>
    </div>
    <div class="next" [class.show]="showChildNextPage">
        <button type="button" mat-mini-fab (click)="nextChildPage()">
            <mat-icon>chevron_right</mat-icon>
        </button>
    </div>

</div>
