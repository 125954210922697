<div class="actions" [@iconAnimation]="showIcons">
    <button mat-icon-button
            [color]="showIcons? 'primary' : ''" 
            (click)="showIcons = !showIcons">
        <mat-icon>{{showIcons ? 'chevron_left' : 'chevron_right'}}</mat-icon>
    </button>
    <button mat-icon-button
            *ngIf="showIcons"
            class="lock-icon"
            [color]="lockApp? 'primary' : ''" 
            (click)="lockApp = !lockApp">
        <mat-icon>{{lockApp ? 'lock' : 'lock_open'}}</mat-icon>
    </button>
    <button mat-icon-button
            *ngIf="showIcons"
            (click)="reload()">
        <mat-icon>refresh</mat-icon>
    </button>
    <button mat-icon-button
            *ngIf="showIcons"
            (click)="exitAgent()">
        <mat-icon>logout</mat-icon>
    </button>
</div>

<app-tile-grid [lockApp]="lockApp" [clientSettings]="clientSettings"></app-tile-grid>
