<app-profile *ngIf="connectedToAgent else selectAgentTemplate"></app-profile>

<ng-template #selectAgentTemplate>

    <form class="select-agent">
        <mat-form-field appearance="fill">
            <mat-label>Agent Code</mat-label>

            <input type="text"
                   matInput
                   name="agentCode"
                   [(ngModel)]="selectedAgentCode"
                   [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" class="server-menu">
                <mat-option *ngFor="let option of rememberedAgentCodes" [value]="option">
                    {{option}}
                    <button mat-icon-button (click)="removeRememberedAgentCode(option); $event.stopPropagation();">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-option>
            </mat-autocomplete>

            <button type="submit" matSuffix mat-icon-button (click)="selectAgent(selectedAgentCode)">
                <mat-icon>login</mat-icon>
            </button>
        </mat-form-field>
        <div style="width: 100%; text-align: center;">{{errorMessage}}&nbsp;</div>
    </form>

</ng-template>
