import { Observable, ReplaySubject, Subject, firstValueFrom } from 'rxjs';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Injectable, NgZone } from '@angular/core';
import { ClientData } from '@launch-deck/common';

@Injectable({
    providedIn: 'root'
})
export class ClientHubService {

    private readonly endpoint: string = "client";
    private connection: HubConnection;
    private connectionSubject: Subject<HubConnection> = new ReplaySubject(1);

    private dataSubject: Subject<ClientData> = new ReplaySubject<ClientData>(1);

    private lastAgentCode?: string;

    public get data(): Observable<ClientData> {
        return this.dataSubject;
    }

    constructor(ngZone: NgZone) {

        // Connect to the server client hub with auto reconnect
        this.connection = new HubConnectionBuilder()
            .withUrl(location.origin + "/" + this.endpoint)
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: () => 5000
            })
            .build();

        // On reconnect, use the new connection for invocation. Request data in case of changes while disconnected
        // TODO: Data should be stored in a replay subject and shared with the client on connection automatically without request
        this.connection.onreconnected(async () => {
            this.connectionSubject.next(this.connection);

            if (this.lastAgentCode) {
                await this.connect(this.lastAgentCode);
            }
        });

        // Clear the connection subject until the connection is restarted to delay invocation until connection is established
        this.connection.onreconnecting(() => {
            this.connectionSubject = new ReplaySubject(1);
        });

        // Watch for Data changes and update the client
        this.connection.on('Data', (data: ClientData) => {
            ngZone.run(() => {
                this.dataSubject.next(data);
            });
        });

        // Start the connection
        this.connection.start().then(() => {
            this.connectionSubject.next(this.connection);
        });
    }

    /**
     * Connect to an agent with the given agentCode
     * 
     * @param agentCode the agentCode identifying the agent to connect to
     */
    public async connect(agentCode: string): Promise<void> {
        this.lastAgentCode = agentCode;
        await this.invoke("Connect", agentCode);
    }

    /**
     * Send commands from a tile by a given id
     * 
     * @param tileId The ID of the tile to send commands for
     */
    public async sendTileCommands(tileId: string): Promise<void> {
        await this.invoke("SendTileCommands", tileId);
    }

    /**
     * Invokes a method with the given arguments on the server ClientHub
     * 
     * @param methodName the name of the method to invoke on the server ClientHub
     * @param args the arguments to pass to the method
     * @returns the response if applicable
     */
    protected async invoke(methodName: string, ...args: any[]): Promise<any> {

        await firstValueFrom(this.connectionSubject);

        try {
            return await this.connection.invoke(methodName, ...args);
        } catch (error) {
            console.error(this.endpoint + "/" + methodName + " invoke error", error);
            throw error;
        }
    }
}
