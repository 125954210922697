import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { combineLatest, map, tap, Subject, BehaviorSubject, ReplaySubject, firstValueFrom, filter } from 'rxjs';
import type { ClientTile, ClientSettings } from "@launch-deck/common";
import { ClientHubService } from 'src/app/services/client-hub.service';

const listAnimation = trigger('listAnimation', [
    transition('* <=> *', [
        query(':enter',
            [style({ opacity: 0 }), stagger('50ms', animate('400ms ease-out', style({ opacity: 1 })))],
            { optional: true }
        )
    ])
]);

@Component({
    selector: 'app-tile-grid',
    templateUrl: './tile-grid.component.html',
    styleUrls: ['./tile-grid.component.css'],
    animations: [listAnimation]
})
export class TileGridComponent implements OnInit {

    @Input() clientSettings: ClientSettings;
    @Input() lockApp: boolean = false;

    @ViewChild('rootTiles', { static: true }) rootTilesElem: ElementRef<HTMLElement>;
    @ViewChild('childTiles', { static: true }) childTilesElem: ElementRef<HTMLElement>;

    tiles: ClientTile[];
    children: ClientTile[];
    hasChildren: boolean = false;

    showRootNextPage: boolean = false;
    showRootPreviousPage: boolean = false;
    showChildNextPage: boolean = false;
    showChildPreviousPage: boolean = false;

    rootChanged: number = 0;
    childChanged: number = 0;

    selectedTile?: ClientTile;
    activeTile?: ClientTile;

    private readonly selectedTileSubject: Subject<ClientTile | undefined> = new Subject();
    private readonly maxTileSubject: Subject<[number, number]> = new ReplaySubject<[number, number]>(1);
    private readonly pageNumberSubject: Subject<[number, number]> = new BehaviorSubject<[number, number]>([1, 1]);

    constructor(private clientHub: ClientHubService) { }

    ngOnInit(): void {

        const tileObservable = this.clientHub.data.pipe(map(data => data.tiles));

        combineLatest([
            tileObservable.pipe(map(tiles => tiles.filter(tile => !tile.parentId))),
            this.maxTileSubject,
            this.pageNumberSubject,
        ])
            .pipe(
                map(([tiles, maxTiles, pageNumber]) => this.getTilesForPage(tiles, maxTiles, pageNumber, true)),
                filter(tiles => !this.tilesEqual(this.tiles, tiles)),
                tap(_ => this.rootChanged++)
            )
            .subscribe(tiles => this.tiles = tiles);

        combineLatest([
            tileObservable,
            this.selectedTileSubject,
            this.maxTileSubject,
            this.pageNumberSubject,
        ])
            .pipe(
                map(([tiles, selected, maxTiles, pageNumber]) => {
                    if (!selected) {
                        return [];
                    }
                    const children = tiles.filter(child => child.parentId === selected.id);
                    return this.getTilesForPage(children, maxTiles, pageNumber, false);
                }),
                filter(tiles => !this.tilesEqual(this.children, tiles)),
                tap(children => this.hasChildren = children.length > 0),
                tap(_ => this.childChanged++)
            )
            .subscribe(tiles => this.children = tiles);

        tileObservable
            .pipe(
                map(tiles => tiles.find(tile => tile.active))
            )
            .subscribe(activeTile => this.setActiveTile(activeTile));

        this.calculateMaxTileCounts();
    }

    @HostListener('window:resize')
    @HostListener('window:orientationchange')
    onResize() {
        this.calculateMaxTileCounts();
    }

    /**
     * Sets the tile that is active according to the host
     * 
     * @param activeTile the host's active tile
     */
    setActiveTile(activeTile?: ClientTile): void {
        this.activeTile = activeTile;

        if (!this.lockApp && this.selectedTile !== this.activeTile) {
            this.selectTile(this.activeTile);
        }
    }

    /**
     * Selects a tile. If the tile is not the active tile, sends the tile's commands
     * 
     * @param tile The tile that was selected
     */
    async selectTile(tile?: ClientTile): Promise<void> {

        if (!tile) {
            this.selectedTile = undefined;
            this.selectedTileSubject.next(undefined);
            return;
        }

        if (tile.id !== this.activeTile?.id) {
            this.clientHub.sendTileCommands(tile.id);
        }

        if (!tile.parentId) {
            this.rootChanged++;
            this.selectedTile = tile;
            this.selectedTileSubject.next(tile);
        }
    }

    async nextRootPage(): Promise<void> {
        if (this.showRootNextPage) {
            const pageNumber = await firstValueFrom(this.pageNumberSubject);
            pageNumber[0] += 1;
            this.pageNumberSubject.next(pageNumber);
        }
    }

    async nextChildPage(): Promise<void> {
        if (this.showChildNextPage) {
            const pageNumber = await firstValueFrom(this.pageNumberSubject);
            pageNumber[1] += 1;
            this.pageNumberSubject.next(pageNumber);
        }
    }

    async previousRootPage(): Promise<void> {
        if (this.showRootPreviousPage) {
            const pageNumber = await firstValueFrom(this.pageNumberSubject);
            pageNumber[0] = Math.max(1, pageNumber[0] - 1);
            this.pageNumberSubject.next(pageNumber);
        }
    }

    async previousChildPage(): Promise<void> {
        if (this.showChildPreviousPage) {
            const pageNumber = await firstValueFrom(this.pageNumberSubject);
            pageNumber[1] = Math.max(1, pageNumber[1] - 1);
            this.pageNumberSubject.next(pageNumber);
        }
    }

    getChildBackgroundStyle(): any {
        return {
            'background-color': this.clientSettings?.theme === 'light' ? "rgba(235, 235, 235, 40%)" : "rgba(20, 20, 20, 40%)"
        };
    }

    getTileStyle(tile: ClientTile): any {
        let tileAlpha = 1;
        if (this.clientSettings?.tileAlpha !== undefined && this.clientSettings.tileAlpha >= 0) {
            tileAlpha = this.clientSettings.tileAlpha;
        }
        let tileBlur = 0;
        if (tileAlpha < 1 && this.clientSettings?.tileBlur !== undefined && this.clientSettings.tileBlur > 0) {
            tileBlur = this.clientSettings.tileBlur;
        }
        const theme = this.clientSettings?.theme || 'dark';

        const defaultBackgroundColor = theme === "dark" ? "#323232" : "#eee";
        const color = theme === "dark" ? "#fff" : "#323232";
        const backgroundColor = this.hexToRgb(tile.color || defaultBackgroundColor, tileAlpha) || "";
        const backdropBrightness = theme === "dark" ? "0.9" : "1.1";
        const backdropFilter = tileBlur > 0 ? `blur(${tileBlur}px) brightness(${backdropBrightness})` : `brightness(${backdropBrightness})`;

        return {
            'background-color': backgroundColor,
            'color': color,
            '-webkit-backdrop-filter': backdropFilter,
            'backdrop-filter': backdropFilter
        }
    }

    private hexToRgb(hex: string, alpha: number): string | null {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
            return r + r + g + g + b + b;
        });

        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha})` : null;
    }

    /**
     * Gets the tiles that should be displayed given the full list of tiles, number of tiles to display, page number and edit mode
     * 
     * @param allTiles the list of all tiles
     * @param maxTiles the max number of tiles that can be displayed in the root and child lists
     * @param pageNumber the current page numbers of the root and child lists
     * @param forRoot if getting the tiles for root (true) or child (false)
     * @returns A sliced list of tiles to display
     */
    private getTilesForPage(allTiles: ClientTile[], maxTiles: [number, number], pageNumber: [number, number], forRoot: boolean): ClientTile[] {
        const top = forRoot ? maxTiles[0] : maxTiles[1];
        const page = (forRoot ? pageNumber[0] : pageNumber[1]);
        const skip = (page - 1) * top;
        const take = skip + top;
        const totalTileCount = allTiles.length;

        if (forRoot) {
            this.showRootPreviousPage = (page > 1);
            this.showRootNextPage = (take < totalTileCount);
        } else {
            this.showChildPreviousPage = (page > 1);
            this.showChildNextPage = (take < totalTileCount);
        }

        return allTiles.slice(skip, take);
    }

    /**
     * Calculates the number of root and child tiles that can be displayed given the size of the elements
     */
    private calculateMaxTileCounts(): void {

        const tileSize = 80;
        const tileGap = 10;
        const padding = 40;

        const rootTilesElemSize = this.getElementSizeWithoutPadding(this.rootTilesElem.nativeElement);
        const childTilesElemSize = this.getElementSizeWithoutPadding(this.childTilesElem.nativeElement);

        const rootColumns = Math.floor((rootTilesElemSize.width - padding) / (tileSize + tileGap));
        const rootRows = Math.floor((rootTilesElemSize.height - padding) / (tileSize + tileGap));

        const childColumns = Math.floor((childTilesElemSize.width - padding) / (tileSize + tileGap));
        const childRows = Math.floor((childTilesElemSize.height - padding) / (tileSize + tileGap));

        this.maxTileSubject.next([rootColumns * rootRows, childColumns * childRows]);
    }

    /**
     * Gets an element's width and height without padding
     * 
     * @param element the element
     * @returns the width and height without padding
     */
    private getElementSizeWithoutPadding(element: Element): { width: number, height: number } {
        const computedStyle = getComputedStyle(element);

        const size = {
            height: element.clientHeight,
            width: element.clientWidth
        };

        size.height -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);
        size.width -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);

        return size;
    }

    private tilesEqual(a: ClientTile[], b: ClientTile[]): boolean {
        if (!a || !b || a.length !== b.length) {
            return false;
        }

        return a.every((o, idx) => this.tileEquals(o, b[idx]));
    }

    private tileEquals(a: ClientTile, b: ClientTile): boolean {
        return (
            a.id === b.id &&
            a.name === b.name &&
            a.icon === b.icon &&
            a.color === b.color &&
            a.parentId === b.parentId
        );
    }

}
