import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AgentCodeService {

    get agentCode(): Observable<string> {
        return this._agentCode;
    }

    private _agentCode: Subject<string> = new ReplaySubject<string>(1);

    setAgentCode(agentCode: string): void {
        this._agentCode.next(agentCode);
    }
}
